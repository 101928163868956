import React from "react"
import { LayoutTabella } from "../components/layout"
import Seo from "../components/seo"
import { Breadcrumb } from "../components/toolbar"
import { SectionNameLink } from "../components/toolbar"
import { ToolbarLink } from "../components/toolbar"
import { TabellaLink } from "../components/tabella"

const LinkPage = () => {
    const Toolbar = () => <ToolbarLink/>
    const Tabella = () => <TabellaLink/>
    const BreadcrumbLink = () => <Breadcrumb arrayLink={[{uri: '/link', titolo: 'Link'}]}/>

    return (
        <LayoutTabella 
            Breadcrumb={BreadcrumbLink} 
            SectionName={SectionNameLink}
            Toolbar={Toolbar} 
            Tabella={Tabella}
        >
            <Seo title="Links" />
        </LayoutTabella>
    )
}

export default LinkPage
